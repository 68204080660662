.highlight-container, .highlight {
    position: relative;
}

.highlight-container {
    display: inline-block;
}
.highlight-container:before {
    content: " ";
    display: block;
    margin-top: 8px;
    height: 80%;
    width: 110%;
    position: absolute;
    background: theme('colors.csprim');
    transform: rotate(2deg);
    top: -1px;
    left: -5px;
    border-radius: 30% 35% 30% 34%;
}

.underline--magical {
    background-image: linear-gradient(to right, theme('colors.csprim'), theme('colors.csprim'));
    background-repeat: no-repeat;
    background-size: 100% 0.2em;
    background-position: 0 88%;
    transition: background-size 0.25s ease-in;
    &:hover {
        background-size: 100% 88%;
    }
}

@keyframes loadName {
    from {
        background-size: 110% 88%;
    }
    to {
        background-size: 100% 0.2em;
    }
}

.underline--magical {
    animation: loadName 750ms ease-in-out;
}

button {
    position: relative;
    display: inline-block;
    padding: 2px 10px 2px 10px;
    text-align: center;
    font-size: 18px;
    letter-spacing: 1px;
    text-decoration: none;
    color: theme('colors.csprim');
    background: transparent;
    cursor: pointer;
    transition: ease-out 0.5s;
    border: 2px solid theme('colors.csprim');
    border-radius: 15px;
    box-shadow: inset 0 5px 10px 0 rgba(0, 0, 0, 0.5);
}

button:hover {
    color: theme('colors.cstxt');
    box-shadow: inset 0 -100px 0 0 theme('colors.csprim');
}

button:active {
    transform: scale(0.9);
}
